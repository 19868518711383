import { Footer } from '@/components/elements/footer/Footer'
import { Header } from '@/components/elements/header/Header'
import { Container } from '@/components/shared/Container'
import { LinkCard } from '@/elements/not-found/LinkCard'
import Head from 'next/head'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import Image from 'next/image'

export type VirtualPageEvent = {
  event: string
  url: string
}

const StyledMain = styled.main`
  text-align: center;
  margin: 72px auto 0;
  padding: 75px 0 95px;
  background: ${(props) => props.theme.colours.backgroundSecondary};

  h1 {
    margin: 0 0 8px;
    font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
    font-family: ${(props) => props.theme.v2.font.nantes};

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
    }
  }

  p {
    font-size: ${(props) => props.theme.v2.typography.body.s.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.s.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    margin-bottom: 48px;

    @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    }

    &.recommended {
      font-size: ${(props) => props.theme.v2.typography.body.xxs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xxs.lineHeight};
      margin-bottom: 36px;
    }
  }
`

export default function NotFound() {
  return (
    <>
      <Head>
        <title>Page not found</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Header />

      <StyledMain id="main-content">
        <Container $size="small">
          <Image
            src="/assets/img/icons/not-found.svg"
            width={52}
            height={54}
            alt="Not found"
            style={{ display: 'inline-block' }}
          />

          <h1>Sorry, we can’t find this page</h1>
          <p>This link no longer exists or the page has been moved</p>
          <p className="recommended">You might be interested in:</p>

          <Swiper
            breakpoints={{
              '0': {
                slidesPerView: 1.3,
                centeredSlides: true,
                spaceBetween: 16,
              },
              '640': {
                slidesPerView: 2.4,
                centeredSlides: true,
                spaceBetween: 20,
              },
              '830': {
                slidesPerView: 3,
                spaceBetween: 20,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <LinkCard
                title="Visit our health academy"
                description="Read the latest nutrition facts and findings from our research"
                image="articles-thumbnail.png"
                url="/learn"
              />
            </SwiperSlide>
            <SwiperSlide>
              <LinkCard
                title="How ZOE works"
                description="Learn more about the ZOE program and our approach to science"
                image="how-zoe-works-thumbnail.png"
                url="/how-it-works"
              />
            </SwiperSlide>
            <SwiperSlide>
              <LinkCard
                title="Results & Reviews"
                description="See what our customers are saying about ZOE"
                image="results-reviews-thumbnail.png"
                url="/results-reviews"
              />
            </SwiperSlide>
          </Swiper>
        </Container>
      </StyledMain>
      <Footer />
    </>
  )
}
