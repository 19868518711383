import { CheckoutCountry, CHECKOUT_GB, CHECKOUT_US, CurrencyCode } from '@/lib/checkout/types'
import { getFirstParamValue } from '@/lib/utils/queryParams'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { GeoContext } from '../../context/geoContext'
import { useCookies } from 'react-cookie'
import { SESSION_COOKIE_CONFIG } from '../utils/cookies'
import { getCountryCurrency } from '../checkout/utils'

const CHECKOUT_COUNTRY_ALLOW_LIST: CheckoutCountry[] = [CHECKOUT_US, CHECKOUT_GB]

export const isValidCheckoutCountry = (country: string): boolean => {
  return CHECKOUT_COUNTRY_ALLOW_LIST.includes(country as CheckoutCountry)
}

export type UseCheckoutCountryType = {
  isReady: boolean
  checkoutCountry?: CheckoutCountry
  currency?: CurrencyCode
  queryParamCountry?: CheckoutCountry
  geoLocCountry: string
  defaultCheckoutCountry: CheckoutCountry
  allowedCheckoutCountries: CheckoutCountry[]
}

const GEO_COUNTRY_COOKIE_NAME = 'cGeoCountry'

export const useCheckoutCountry = (): UseCheckoutCountryType => {
  const router = useRouter()
  const { country } = useContext(GeoContext)
  const [checkoutCountry, setCheckoutCountry] = useState<CheckoutCountry>()
  const [currency, setCurrency] = useState<CurrencyCode>()
  const [queryParamCountry, setQueryParamCountry] = useState<CheckoutCountry>()
  const [isReady, setIsReady] = useState<boolean>(false)
  const [_, setCookie] = useCookies([GEO_COUNTRY_COOKIE_NAME])

  useEffect(() => {
    if (!router.isReady) {
      return
    }

    const queryCountry = (getFirstParamValue(router.query, 'country') || '').toUpperCase()

    if (!queryCountry && !country) {
      return
    }

    // First preference is country query string
    // Second preference is geoCountry
    if (isValidCheckoutCountry(queryCountry)) {
      setCheckoutCountry(queryCountry as CheckoutCountry)
      setCurrency(getCountryCurrency(queryCountry as CheckoutCountry))
      setQueryParamCountry(queryCountry as CheckoutCountry)
    } else if (isValidCheckoutCountry(country)) {
      setCheckoutCountry(country as CheckoutCountry)
      setCurrency(getCountryCurrency(country as CheckoutCountry))
    }

    setIsReady(true)
  }, [country, router.query, router.isReady])

  return {
    isReady,
    checkoutCountry,
    currency,
    queryParamCountry,
    geoLocCountry: country,
    defaultCheckoutCountry: CHECKOUT_GB,
    allowedCheckoutCountries: CHECKOUT_COUNTRY_ALLOW_LIST,
  }
}
