import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'

const StyledCard = styled.div`
  background-color: white;
  padding: 10px 10px 40px;
  height: 440px;
`

const StyledCardImage = styled.div`
  margin-bottom: 38px;
`

const StyledContent = styled.div`
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    h3 {
      margin: 0 0 8px;
      font-size: ${(props) => props.theme.v2.typography.body.m.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.m.lineHeight};
      font-family: ${(props) => props.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.onSurface.text};
    }

    p {
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
      color: ${(props) => props.theme.v2.onSurface.subduedText};
      padding: 0 16px;
      margin-bottom: 0;

      @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        padding: 0;
      }
    }
  }
`

const StyledLinkIcon = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2378 1.04956L15.9224 5.73414C16.0688 5.88059 16.0688 6.11803 15.9224 6.26447L11.2378 10.9491M15.6127 6.0045L1.5 6.00482' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${(props) => props.theme.v2.button.primary.bgColor};
  transition: background-color 0.3s ease-in;

  &:hover {
    background-color: ${(props) => props.theme.v2.button.primary.hoverColor};
  }
`

type LinkCardProps = {
  title: string
  description: string
  image: string
  url: string
}

export const LinkCard = ({ title, description, image, url }: LinkCardProps) => {
  return (
    <StyledCard>
      <Link href={url} passHref>
        <StyledCardImage>
          <Image src={`/assets/img/${image}`} width={286} height={180} alt={`${title} image`} />
        </StyledCardImage>
        <StyledContent>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>

          <StyledLinkIcon />
        </StyledContent>
      </Link>
    </StyledCard>
  )
}
